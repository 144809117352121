:root{
  --template-color:#212121;
  --partner-background:#393939;
  --public-sale:#004FBD;
  --liquidity:#0065F2;
  --staking:#197AFF;
  --Team:#4794FF;
  --Marketing:#7DB4FF;
  --reserve:#7DB4FF;
  --strategic-partners:#8FBEFF;
  --Bounty:#A3C9FF;
  --Advisors:#D2E5FF;
}
#root{
  overflow-x: hidden !important;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url(./Assets/fonts/HelveticaNeue.ttf);
}
@font-face {
  font-family: 'HelveticaNeue-bold';
  src: url(./Assets/fonts/HelveticaNeue-Bold.ttf);
}
@font-face {
  font-family: 'HelveticaNeue-medium';
  src: url(./Assets/fonts/HelveticaNeue-Medium.ttf);
}
@font-face {
  font-family: '1mfont';
  src: url(./Assets/fonts/Akira\ Expanded\ Demo.otf);
}
*{
  font-family: 'HelveticaNeue';
  font-weight: 500;
}
.container{
  padding-left: 42px !important;
  padding-right: 42px !important;
}
header{
  background-color: var(--template-color);
}
.content-wrapper{
  background-color: var(--template-color);
}
h1{
  font-family: 'HelveticaNeue-bold';
  font-size: 32px;
  line-height: 38px;
  max-width: 290px;
}
h2{
  font-family: 'HelveticaNeue-bold';
  font-size: 32px;
}
/* Hovers */
.btn-styling:hover{
  background-color: white !important;
  color: #0065F2 !important;
  box-shadow: 0px 0px 20px -8px white
  !important;
  transition: 0.5s 0.2s ease-in-out;
}
.services-image:hover{
  cursor: pointer;
  width: 200px;
  transition-duration: 0.8s;
  transition-delay: 0.3s;
  transition-timing-function: ease-in-out;
}
/* first section styling  */
h1 strong{
  font-family: '1mfont';
  color: #2DCB48;
}
.first-section-styling{
  height: 651px;
}
.first-section-styling .play-video{
  background-image: url(./Assets/images/applicaion_crypto_funding_talent.svg);
  background-position-x: center;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-size: 100%;
}

.play-video{
  min-height: 300px;
  max-height: 651px;
}
.first-section-styling .play-video img{
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.btn-styling{
  padding: 12px 30px 12px 30px;
}
.first-section-styling .swiper-pagination{
  margin-top: 20px;
}

/* END FIRST SECTION STYLING  */

/* partner swiper styling  */
.partner-swiper-styling{
  background-color: var(--partner-background);
}
.partner-slider-styling p{
  max-width: 283px;
}
.partner-swiper-styling .swiper-button-next::after,
.partner-swiper-styling .swiper-button-prev::after
{
  font-size: 12px;
  font-weight: bold;
  color: white;
}
.partner-swiper-styling .swiper-button-next{
  right: 0;
}
.partner-swiper-styling .swiper-button-prev
{
  left: 0;
}
.partner-swiper-styling .swiper{
  height: 120px;
}
.partner-swiper-styling .swiper .swiper-pagination{
bottom: 0 !important;
}
/* .partner-swiper-styling .swiper .swiper-wrapper{
  height: 120px;
} */
.partner-image{
  width: 60px !important;
}

/* end partNer swiper styling  */

/* second section styling  */
.second-section-styling main h2{
  font-size: 32px;
  max-width: 507px;
  font-weight: 500;
}
.second-section-styling main p{
  min-width: 253px;
  max-width: 445px;
}

/* end second section styling  */

/* our services styling  */
.services-title{
  margin-bottom: 10px;
}
.our-services-styling{
  margin-top: 70px;
}
.our-services-styling h2{
  font-size: 32px;
  font-weight: 500;
  border-bottom: 1px solid var(--partner-background);
  white-space: nowrap;
}
.our-services-styling p{
  font-size: 18px;
}
.Our-services-font-style main h3{
  font-size: 18px ;
  white-space: nowrap;
}
.Our-services-font-style main p{
  font-size: 14px;
  min-width: 255px;
  max-width: 319px;
}
.our-services-styling .swiper-button-next::after,
.our-services-styling .swiper-button-prev::after
{
  color: white;
  font-size: 18px;
}
.our-services-styling .swiper-button-next,
.our-services-styling .swiper-button-prev
{
  
  top: 50% !important;
}
/* end our services styling  */

/* platform features styling  */
.platform-features-styling{
  margin-top: 80px;
}
.platform-features-styling main h2{
  white-space: nowrap;
font-weight: 500;
}
.platform-features-styling main p{
  max-width: 614px;
}
.platform-feature-font-styling h3{
  font-size: 18px ;
}
.platform-feature-font-styling span{
  font-size: 14px ;
  font-weight: 500;
  
}
.platform-features-description main span{
  font-size: 18px;
  font-weight: 500;
}
.platform-features-description main h3{
  font-size: 32px;
  font-weight: 700;
}
.platform-features-description main p{
  font-size: 16px;
  font-weight: 400;
}
.platform-features-navigation .swiper-wrapper .swiper-slide-thumb-active{
background: rgb(0,101,242);
background: linear-gradient(180deg, rgba(0,101,242,0) 0%, rgba(0,101,242,0.4037814955083596) 40%); 
border-bottom: 2px solid white;
}
.platform-features-navigation .swiper-wrapper .swiper-slide{
  cursor: pointer;
}
.platform-features-navigation .swiper-button-next::after,
.platform-features-navigation .swiper-button-prev::after
{
  font-size: 18px;
  color: white;
}
.platform-features-h3{
  margin-top: 16px;
  margin-bottom: 16px;
}
.platform-features-p{
  margin-bottom: 32px !important;
}
/* end platform features styling  */

/* how it works styling  */
.how-it-works-styling main h2{
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--partner-background);
}
.how-it-works-styling main p{
  max-width: 513px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.how-it-works-card {
  background-color: white;
  width: 340px;
  height: 400px;
  border-radius: 15px;
  box-shadow: 0px 2px 10px 8px #0065F21F;
}
.how-it-works-card-blue{
  width: 340px;
  height: 500px;
  border-radius: 15px;
}
.how-it-works-styling  div .how-it-works-card main,
.how-it-works-styling div div main
{
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 24px;
}
.how-it-works-styling  div .how-it-works-card main span,
.how-it-works-styling div div main span
{
  font-family: 'HelveticaNeue-medium';
  font-size: 56px;
  font-weight: 700;
  opacity: 0.4;
}
.how-it-works-styling  div .how-it-works-card div,
.how-it-works-styling div .how-it-works-card-blue div 
{
  padding-right: 20px;
  padding-left: 20px;
}
.how-it-works-styling  div .how-it-works-card div h3,
.how-it-works-styling div .how-it-works-card-blue div h3
{
  font-size: 24px;
  font-family: 'HelveticaNeue-bold';
  font-weight: 500;
  max-width: 238px;
}
.how-it-works-styling  div .how-it-works-card div p,
.how-it-works-styling div .how-it-works-card-blue p
{
  margin-top: 40px;
  font-family: 'HelveticaNeue';
}
.how-it-works-styling  div .how-it-works-card div ul,
.how-it-works-styling div .how-it-works-card-blue div ul
{
  margin-top: 10px;
  padding-left: 8px;
}
.how-it-works-styling  div .how-it-works-card div ul li,
.how-it-works-styling div .how-it-works-card-blue div ul li
{
  font-family: 'HelveticaNeue';
  margin-bottom: 4px;
  padding-left: 8px;
}
.how-it-works-styling  div .how-it-works-card div ul li::marker,
.how-it-works-styling div .how-it-works-card-blue div ul li::marker
{
 font-size: 10px;
}
.dashed-line{
  position: absolute;
  width: 371px;
  border: 1px dashed white ;
  top: 12%;
  left: 15%;
}
/* end How it works styling  */

/* Time Line Styling  */
.time-line-styling{
  margin-top: 80px;
  padding-top: 8%;
  height: 720px;
}
.time-line-styling h2{
  font-size: 40px;
}
.time-line-3d{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 42px;
  padding-right: 42px;
}
.the-timeline-swiper{
  height: 200px;
}
/* end Time Line Styling  */

/* Why cft styling  */

.Why-Cft-styling main h2{
  font-weight: 500;
  max-width: 445px;
}
.Why-Cft-styling main p{
  max-width: 614px;
}
.whycft-h3{
  margin-top: 16px;
  margin-bottom: 16px;
}
.whycft-p{
  margin-bottom: 32px;
}
/* end why cft styling  */

/* New world cft styling  */
.NewWorldCft-styling{
  margin-top: 80px;
}
.NewWorldCft-styling main h2{
  max-width: 427px;
}
.NewWorldCft-styling main p{
  margin-top: 20px;
  max-width: 441px;
}

/* end new world cft styling  */

/* start tokenomics styling  */

.tokenomics-styling{
  margin-top: 80px;
}
.tokenomics-styling h2{
  padding-bottom: 20px;
  border-bottom: 1px solid var(--partner-background);
}
.tokenomics-card{
  width: 340px;
  height: 146px;
  padding-top: 2.5rem;
  border: 1px solid white;
  border-radius: 15px;
}
.tokenomics-card main h4{
  font-family: 'HelveticaNeue-medium';
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
.tokenomics-card main span{
  font-family: 'HelveticaNeue-medium';
  font-size: 24px;
  font-weight: 700;
}
.tokenomic-state{
  width: 312px;
  height: 76px;
  border-top: 1px solid white;
  padding-right: 30px;
}
.tokenomic-state span{
  font-size: 18px;
  font-family: 'HelveticaNeue';
  font-weight: 400;
}
.tokenomic-state div span{
  font-size: 16px;
  font-family: 'HelveticaNeue';
  font-weight: 400;
}
.public-sale-color{
  background-color: var(--public-sale);
  width: 39px;
  height: 24px;
}
.Liquidity-color{
  background-color: var(--liquidity);
  width: 39px;
  height: 24px;
}
.staking-color{
  background-color: var(--staking);
  width: 39px;
  height: 24px;
}
.Team-color{
  background-color: var(--staking);
  width: 39px;
  height: 24px;
}
.Marketing-color{
  background-color: var(--Marketing);
  width: 39px;
  height: 24px;
}
.reserve-color{
  background-color: var(--reserve);
  width: 39px;
  height: 24px;
}
.StartegicP-color{
  background-color: var(--strategic-partners);
  width: 39px;
  height: 24px;
}
.Bounty-color{
  background-color: var(--Bounty);
  width: 39px;
  height: 24px;
}
.Advisors-color{
  background-color: var(--Advisors);
  width: 39px;
  height: 24px;
}
.tokenomics-logo{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 80px;
}
.tokenomics-readmore-btn{
  width: 100%;
}
/* end tokenomics styling  */

/* meet the team styling  */
.Meet-Team-styling{
  margin-top: 80px;
}
.Meet-Team-styling main h2{
  padding-bottom: 20px;
  border-bottom: 1px solid var(--partner-background);
}
.team-card{
  width: 340px;
  height: 321px;
  padding: 24px;
  padding-bottom: 0px;
  background-color: white;
  border-radius: 15px;
}
.team-card p{
  font-family: 'HelveticaNeue';
  width: 286px;
  font-size: 18px;
  margin-bottom: 65px;
}
.team-card div div main h5{
  font-weight: 500;
  margin-bottom: 0px;
}
.team-card div div main span{
  font-size: 14px;
}
.team-card div div div a{
  font-size: 20px;
}
/* end meet the team styling  */


/* news-letter-styling  */
.news-letter-styling{
  background-color: #1E1E1E;
  height: 321px;
}
.news-letter-styling h2{
  max-width: 540px;
  font-size: 40px;
}
.news-letter-styling form input{
  width: 218px;
  height: 48px;
  padding-left: 18px;
  outline: none;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.news-letter-styling form button{
  width: 111px;
  height: 48px;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
/* end news letter styling  */

/* footer styling */
.footer-styling div nav a,
.footer-styling div p
{
  font-size: 14px;
}
.footer-styling div p{
  width: 125px;
}
.footer-styling div hr{
  width: 250px !important;
}
/* end footer styling */

/* Copy right styling  */
.copyright-styling span,
.copyright-styling nav a
{
  font-size: 14px;
}
/* END COPYRIGHT STYLING  */

/* error page styling  */
.error-page-styling div h1{
  font-size: 200px;
}
.error-page-styling div strong{
  font-size: 40px;
}
/* END ERROR PAGE STYLING  */
.spline-3d{
  width: 100% !important;
  height: 100% !important;
}

@media (min-width: 576px) { 
  .first-section-styling .play-video{
    background-position-x: center;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-size: 77%;
  }
  .time-line-styling{
    margin-top: 80px;
    padding-top: 8%;
    height: 800px;
  }
}

@media (min-width: 768px) { 
  .first-section-styling{
    height: 420px;
  }
  .first-section-styling .play-video{
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .second-section-styling main h2{
    font-size: 40px;
  }
  .partner-image{
    width: 80px !important;
  }
  h1{
    font-size: 40px;
    line-height: 48px;
    max-width: 442px;
  }
  h2{
    font-size: 40px;
  }
  .how-it-works-styling main h2{
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: none;
  }
  .time-line-styling{
    margin-top: 80px;
    padding-top: 8%;
    height: 800px;
  }
  .news-letter-styling form input{
    width: 350px;
  }
  .news-letter-styling form button{
    width: 136px;
  }
  /* monaime changes  */
  .our-services-styling .swiper-button-next,
  .our-services-styling .swiper-button-prev
  {
    
    top: 40% !important;
  }
  .how-it-works-card{
    width: 100%;
    height: 350px;
  }
  /* monaime changes */

  .how-it-works-card-blue{
    width: 100%;
    height: 380px;
  }
  .second-section-styling main {
    background-image: url(./Assets/images/world_frame.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    
  }
  .team-card{
    width: 310px;
  }
  .tokenomic-state{
    width: 100%;
  }
  .tokenomics-card{
    width: 100%;
  }
  .our-services-styling p{
    font-size: 16px;
    margin-bottom: 0px !important;
  }
  .our-services-styling h2{
    font-size: 40px ;
  }
  .Why-Cft-styling main p,.platform-features-styling main p{
    margin-bottom: 30px !important;
  }
  .platform-feature-img,
  .whycft-img
  {
    margin-bottom: 30px;
  }
  .tokenomics-readmore-btn{
    width: unset;
  }
}

@media (min-width: 992px) {
  .first-section-styling{
    height: 651px;
  }
  .first-section-styling .play-video{
    background-position-x: center;
    background-position-y: 1%;
    background-size: 100%;
  }
  .partner-image{
    width: 80px !important;
  }
  h2{
    font-size: 40px;
  }
  h1{
    font-size: 49px;
    line-height: 60px;
    max-width: 555px;
  }
  .time-line-styling{
    margin-top: 80px;
    padding-top: 8%;
    height: 500px;
  }
  .time-line-3d{
    display: unset;
    position: absolute;
    right: 10%;
    top: 0;
    margin-left: unset;
  margin-right: unset;
  padding-left: unset;
  padding-right: unset;
  }
  /* monaime changes */
  .how-it-works-card {
    background-color: white;
    width: 340px;
    height: 400px;
    border-radius: 15px;
    box-shadow: 0px 2px 10px 8px #0065F21F;
  }
  .how-it-works-card-blue{
    width: 340px;
    height: 500px;
    border-radius: 15px;
  }
  .second-section-styling main {
    background: unset;
  }
  .team-card{
    width: 340px;
  }
  .tokenomic-state{
    width: 310px;
  }
  .tokenomics-card{
    width: 340px;
  }
  .our-services-styling p{
    font-size: 18px;
  } 
  .Why-Cft-styling main h2,.platform-features-styling main h2{
    margin-bottom: 20px;
  }
  .how-it-works-styling main h2{
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) { 
  .first-section-styling .play-video{
    background-position-x: center;
    background-position-y: 1%;
    background-size: 100%;
  }
  h1{
    font-size: 49px;
    line-height: 60px;
    max-width: 555px;
  }
  h2{
    font-size: 40px;
  }
  .partner-image{
    width: 100px !important;
  }
  
}
